<template>
  <div>
    <div class="main">
      <div class="box">
        <div class="title">
          <img src="../../assets/pc/音量.png" />
          <span>{{ $t('pcproblem').p1 }}</span>
        </div>
        <ul class="ul1">
          <li>
            <div class="top">
              <p class="round"></p>
              <span>{{ $t('pcproblem').p2 }} </span>
            </div>
            <p class="p1">
              {{ $t('pcproblem').p3 }}
            </p>
          </li>
          <li>
            <div class="top">
              <p class="round"></p>
              <span>{{ $t('pcproblem').p4 }} </span>
            </div>
            <p class="p1">
              {{ $t('pcproblem').p5 }}
            </p>
          </li>
          <li>
            <div class="top">
              <p class="round"></p>
              <span>{{ $t('pcproblem').p6 }} </span>
            </div>
            <p class="p1">
              {{ $t('pcproblem').p7 }}
            </p>
          </li>
          <li>
            <div class="top">
              <p class="round"></p>
              <span>{{ $t('pcproblem').p8 }} </span>
            </div>
            <p class="p1">
              {{ $t('pcproblem').p9 }}
            </p>
          </li>
          <li>
            <div class="top">
              <p class="round"></p>
              <span>{{ $t('pcproblem').p10 }} </span>
            </div>
            <p class="p1">
              {{ $t('pcproblem').p11 }}
            </p>
          </li>
          <li>
            <div class="top">
              <p class="round"></p>
              <span>{{ $t('pcproblem').p12 }} </span>
            </div>
            <p class="p1">
              {{ $t('pcproblem').p13 }}
            </p>
          </li>
          <li>
            <div class="top">
              <p class="round"></p>
              <span>{{ $t('pcproblem').p14 }} </span>
            </div>
            <p class="p1">
              {{ $t('pcproblem').p15 }}
            </p>
          </li>
          <li>
            <div class="top">
              <p class="round"></p>
              <span>{{ $t('pcproblem').p16 }} </span>
            </div>
            <p class="p1">
              {{ $t('pcproblem').p17 }}
            </p>
          </li>
          <li>
            <div class="top">
              <p class="round"></p>
              <span>{{ $t('pcproblem').p18 }} </span>
            </div>
            <p class="p1">
              {{ $t('pcproblem').p9 }}
            </p>
          </li>
          <li>
            <div class="top">
              <p class="round"></p>
              <span>{{ $t('pcproblem').p20 }} </span>
            </div>
            <p class="p1">
              {{ $t('pcproblem').p21 }}
            </p>
          </li>
          <li>
            <div class="top">
              <p class="round"></p>
              <span>{{ $t('pcproblem').p22 }} </span>
            </div>
            <p class="p1">
              {{ $t('pcproblem').p23 }}
            </p>
          </li>
          <li>
            <div class="top">
              <p class="round"></p>
              <span>{{ $t('pcproblem').p24 }} </span>
            </div>
            <p class="p1">
              {{ $t('pcproblem').p25 }}<br />
              {{ $t('pcproblem').p26 }}<br />
              {{ $t('pcproblem').p27 }}<br />
              {{ $t('pcproblem').p28 }}<br />
              {{ $t('pcproblem').p29 }}<br />
              {{ $t('pcproblem').p30 }}<br />
              {{ $t('pcproblem').p31 }}<br />
              {{ $t('pcproblem').p32 }}<br />
              {{ $t('pcproblem').p33 }}<br />
              {{ $t('pcproblem').p34 }}<br />
              {{ $t('pcproblem').p35 }}<br />
              {{ $t('pcproblem').p36 }}<br />
              {{ $t('pcproblem').p37 }}
            </p>
          </li>
          <li>
            <div class="top">
              <p class="round"></p>
              <span>{{ $t('pcproblem').p38 }} </span>
            </div>
            <p class="p1">
              {{ $t('pcproblem').p39 }}
            </p>
          </li>
          <li>
            <div class="top">
              <p class="round"></p>
              <span>{{ $t('pcproblem').p40 }} </span>
            </div>
            <p class="p1">
              {{ $t('pcproblem').p41 }}
            </p>
          </li>
          <li>
            <div class="top">
              <p class="round"></p>
              <span>{{ $t('pcproblem').p42 }} </span>
            </div>
            <p class="p1">
              {{ $t('pcproblem').p43 }}
            </p>
          </li>
          <li>
            <div class="top">
              <p class="round"></p>
              <span>{{ $t('pcproblem').p44 }} </span>
            </div>
            <p class="p1">
              {{ $t('pcproblem').p45 }}
            </p>
          </li>
          <li>
            <div class="top">
              <p class="round"></p>
              <span>{{ $t('pcproblem').p46 }} </span>
            </div>
            <p class="p1">
              {{ $t('pcproblem').p47 }}
            </p>
          </li>
          <li>
            <div class="top">
              <p class="round"></p>
              <span>{{ $t('pcproblem').p48 }} </span>
            </div>
            <p class="p1">
              {{ $t('pcproblem').p49 }}<br />
              <br />
              {{ $t('pcproblem').p50 }}<br />
              <br />
              {{ $t('pcproblem').p51 }}
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CurrencyMobileNewIndex',

  data() {
    return {}
  },

  mounted() {},

  methods: {}
}
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
.main {
  width: 100%;
  padding: 30px 0 60px;
  background-color: #f8f8f8;
  .box {
    width: 684px;
    min-height: 800px;
    padding: 20px 30px 60px;
    position: relative;
    background-color: #fff;
    border-radius: 8px;
    box-sizing: border-box;
    margin: 0 auto;
    .title {
      width: 100%;
      font-family: PingFang SC;
      margin-bottom: 30px;
      text-align: left;
      font-weight: 500;
      align-items: center;
      display: flex;
      box-sizing: border-box;
      img {
        width: 40px;
        height: 40px;
        margin-right: 10px;
        border-style: none;
      }
      span {
        color: #424344;
        font-size: 18px;
      }
    }
    .ul1 {
      padding-left: 27px;
    }
    ul {
      width: 100%;
      padding-left: 27px;
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        margin-bottom: 20px;
        box-sizing: border-box;
        .top {
          margin-bottom: 6px;
          cursor: pointer;
          display: flex;
          align-items: center;
          height: 32px;
          justify-content: flex-start;
          width: 100%;
          .round {
            width: 8px;
            height: 8px;
            align-items: center;
            position: relative;
            border-radius: 50%;
            background: #424344;
            margin-right: 15px;
          }
          span {
            width: 90%;
            overflow: hidden;
            color: #424344;
            font-weight: 600;
            text-align: left;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 14px;
            line-height: 32px;
          }
        }
        .p1 {
          margin-left: 23px;
          color: #989898;
          text-align: left;
          font-size: 10px;
          line-height: 1.15;
        }
      }
    }
  }
}
</style>
